/** Job address fragment */
export const ADDRESS_FRAGMENT = `
fragment addressFragment on JobAddress {
  _id
  address
  district_id
  district_name
  lat
  lng
}
`

/** Company fragment */
export const COMPANY_FRAGMENT = `
fragment companyFragment on AccessibleCompany {
  _id
  about
  business_registration_certificate
  company_logo_thumbnail
  company_size
  contact_person
  email
  industry
  is_suspended
  suspended_reason
  legal_business_name
  name
  owner
  role
  telephone
  youtube
}
`

/** Core fragment for jobs */
export const JOB_FRAGMENT_CORE = `
fragment jobFragmentCore on CompanyJob {
  _created_at
  _id
  address {
    ...addressFragment
  }
  employment_type
  is_suspended
  job_name
  promotion_budget
  promotion_currency
  promotion_state
  published_at
  state
  unread_application_count
  suspended_reason
}

${ADDRESS_FRAGMENT}
`

/** Full fragment for jobs */
export const JOB_FRAGMENT_FULL = `
fragment jobFragmentFull on CompanyJob {
  ...jobFragmentCore
  _updated_at
  allowances {
    description
    name
  }
  alternative_sat
  attributes {
    _id
    category
    name
  }
  career_level
  cert {
    name
  }
  closed_at
  company
  education_requirement {
    _id
    category
    name
  }
  end_date
  from_hourly_rate
  from_monthly_rate
  from_working_days_per_week
  from_working_hours_per_day
  job_description
  job_types {
    _id
    category
    name
  }
  shift_required
  skill {
    name
  }
  spoken_skill {
    level
    name
  }
  start_date
  subscriber {
    channel
    subscriber
  }
  to_hourly_rate
  to_monthly_rate
  to_working_days_per_week
  to_working_hours_per_day
  vacancy
  working_hour {
    day_of_week
    end_time
    start_time
  }
  written_skill {
    level
    name
  }
}

${JOB_FRAGMENT_CORE}
`

/** User profile fragment */
export const PROFILE_FRAGMENT = `
fragment profileFragment on UserProfile {
  _id
  companies {
    ...companyFragment
  }
  email
  telephone
}

${COMPANY_FRAGMENT}
`

/** Core fragment for applications */
export const APPLICATION_FRAGMENT_CORE = `
fragment applicationFragmentCore on Application {
  _created_at
  _id
  applied_addresses {
    ...addressFragment
  }
  applied_job {
    _id
    job_name
    # ...jobFragmentCore
  }
  is_contact_viewed
  is_rejected
  is_reviewed
  is_saved
  name
  profile_picture_thumbnail
  profile_picture_medium
  seeker
}

${ADDRESS_FRAGMENT}
`

/** Full fragment for applications */
export const APPLICATION_FRAGMENT_FULL = `
fragment applicationFragmentFull on Application {
  ...applicationFragmentCore
  _updated_at
  certificates {
    name
  }
  contact_viewed_at
  education {
    _id
    category
    name
  }
  rejected_at
  reviewed_at
  message_to_employer
  skills {
    name
  }
  spoken_skills {
    level
    name
  }
  work_experience {
    company_name
    description
    employment_type
    end_date
    position_name
    position_type
    start_date
  }
  written_skills {
    level
    name
  }
}

${APPLICATION_FRAGMENT_CORE}
`

/** Full fragment for credit card */
export const CREDIT_CARD_FRAGMENT_FULL = `
fragment creditCardFragmentFull on CreditCardSummary {
  brand
  expiry_date
  last_4
}
`

/** Full fragment for invoices */
export const INVOICE_FRAGMENT_FULL = `
fragment invoiceFragmentFull on Invoice {
  _created_at
  description
  invoice
  invoice_id
  product_type
  payment_url
  status
}
`

/** Full fragment for receipt */
export const RECEIPT_FRAGMENT_FULL = `
fragment receiptFragmentFull on Receipt {
  _created_at
  description
  product_type
  receipt
  transaction_type
}
`

export const JOB_COUNT_FRAGMENT = `
query QueryJobs (
  $company: ID
  $exclude: [ID]
  $district: [ID]
  $employment: [Employment]
  $workingHour: [JobSearchWorkingHour]
  $fromWorkingDaysPerWeek: Float
  $fromWorkingHoursPerDay: Float
  $hourlyRate: Float
  $monthlyRate: Float
  $jobType: [ID]
  $term: String
  $latlng: MLatLng
) {
  job_search(
    company: $company
    exclude: $exclude
    term: $term,
    district: $district,
    employment: $employment,
    from_working_days_per_week: $fromWorkingDaysPerWeek,
    from_working_hours_per_day: $fromWorkingHoursPerDay,
    hourly_rate: $hourlyRate,
    monthly_rate: $monthlyRate,
    job_type: $jobType,
    limit: 0,
    offset: 0,
    working_hour: $workingHour,
    latlng: $latlng
  ) {
    ...jobSearchResultFragment
  }
}

fragment jobSearchResultFragment on JobSearchResult {
  total
}
`
