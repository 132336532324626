<template>
  <nav class="side-nav">
    <!-- Mobile nav items -->
    <ol class="side-nav__items side-nav__items--mobile">
      <side-nav-item
        v-for="(item, i) in items.mobile"
        :key="`SidebarNavItemMobile${i}`"
        :active="item.active"
        :icon="item.icon"
        :href="item.href"
        :child-path-pattern="item.childPathPattern"
        :new-feature="item.new"
        :class="`w-1/${items.mobile.length}`"
        :link-path="item.path"
        :link-query="item.query"
        :count="item.count"
        :title="item.title"
      />
    </ol>
    <!-- Desktop nav items -->
    <ol class="side-nav__items side-nav__items--desktop">
      <side-nav-item
        v-for="(item, i) in items.desktop"
        :key="`SidebarNavItemDesktop${i}`"
        :active="item.active"
        :icon="item.icon"
        :href="item.href"
        :child-path-pattern="item.childPathPattern"
        :new-feature="item.new"
        :link-path="item.path"
        :link-query="item.query"
        :count="item.count"
        :title="item.title"
      />
    </ol>
  </nav>
</template>

<script>
import { getLinkByKey, LinkKeys } from '../../libs/mapping/link'
import SidebarNavItem from './sidebarNavItem'

/**
 * Nav item definitions
 */
const items = {
  applications: {
    childPathPattern: '/applications/',
    icon: 'svg-applicant',
    path: '/applications/',
    titleKey: 'applications.title',
  },
  company: {
    childPathPattern: '/profile/',
    icon: 'svg-profile',
    titleKey: 'company.title',
  },
  jobs: {
    childPathPattern: '/jobs/detail/',
    icon: 'svg-job',
    path: '/',
    titleKey: 'jobs.title',
  },
}

export default {
  components: {
    'side-nav-item': SidebarNavItem,
  },
  computed: {
    items() {
      return {
        /**
         * Desktop nav items
         */
        desktop: [
          {
            ...items.jobs,
            active: !this.companySuspended,
            title: this.$t(items.jobs.titleKey),
          },
          {
            ...items.applications,
            active: !this.companySuspended,
            count: this.unreadApplicationCount,
            title: this.$t(items.applications.titleKey),
          },
          {
            ...items.company,
            // Company public profile page
            path: '/profile/public/',
            title: this.$t(items.company.titleKey),
          },
          {
            childPathPattern: '/billing/payment-methods/',
            icon: '',
            path: '/billing/payment-methods/',
            query: {
              event_source: this.eventSource,
            },
            title: this.$t('paymentMethods.title'),
          },
          {
            childPathPattern: '/billing/invoices/',
            icon: '',
            path: '/billing/invoices/',
            title: this.$t('invoice.title'),
          },
          {
            href: getLinkByKey(this.currentLocale.code, LinkKeys.bidding),
            icon: '',
            path: '',
            title: this.$t('jobPromotion.faqTitle'),
          },
          {
            href: getLinkByKey(
              this.currentLocale.code,
              LinkKeys.communityGuidelines,
            ),
            icon: '',
            path: '',
            title: this.$t('link.communityGuidelines'),
          },
        ],

        /**
         * Mobile nav items
         */
        mobile: [
          {
            ...items.jobs,
            active: !this.companySuspended,
            title: this.$t(`${items.jobs.titleKey}Mobile`),
          },
          {
            ...items.applications,
            active: !this.companySuspended,
            count: this.unreadApplicationCount,
            title: this.$t(`${items.applications.titleKey}Mobile`),
          },
          {
            ...items.company,
            // Mobile employer menu
            path: '/profile/',
            title: this.$t(`${items.company.titleKey}Mobile`),
          },
        ],
      }
    },

    unreadApplicationCount() {
      if (!this.unreadCount) {
        return ''
      }
      return this.unreadCount > 99 ? '99+' : `${this.unreadCount}`
    },
  },
  data() {
    return {
      /**
       * Event source
       */
      eventSource: 'nav.menu',
    }
  },
  props: {
    /**
     * Whether company is suspended
     */
    companySuspended: {
      default: false,
      type: Boolean,
    },

    /**
     * Whether company is suspended
     */
    unreadCount: {
      default: null,
      type: Number,
    },
  },
}
</script>

<style lang="postcss" scoped>
.side-nav {
  @apply w-full;
}

@screen mobile {
  .side-nav {
    @apply h-full px-3;
  }
  .side-nav__items {
    @apply flex justify-center;
    @apply w-full max-w-form h-full mx-auto;
  }

  /* Hide desktop nav items */
  .side-nav__items--desktop {
    @apply hidden;
  }
}

@screen desktop {
  .side-nav-item:not(:first-child) {
    @apply mt-2;
  }

  /* hidden the side bar icon on desktop level */
  .side-nav-item::v-deep svg {
    @apply hidden;
  }

  /* Hide mobile nav items */
  .side-nav__items--mobile {
    @apply hidden;
  }
}
</style>
